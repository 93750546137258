import { Link } from 'gatsby';
import Layout from 'components/layout';
import React from 'react';

// Styles
import * as styles from './404.module.scss';

const NotFoundPage = () => {
  const description = 'Reverso is a Fractional RF device that incorporates several innovative technological solutions, all intended to guarantee optimal clinical results skin resurfacing, tissue remodeling, stretch mark treatment and anti-aging treatment.';

  return (
    <Layout title="Reverso by INDIBA | Deep Fractional Radiofrequency for Skin Aging Treatment" meta={{ description, image: 'https://intelis-public.s3.us-east-2.amazonaws.com/image-device.png' }}>
      <div className={styles.Wrapper}>
        <div className={styles.Content}>
          <title>Not found</title>
          <h1 className={styles.Heading}>Page not found</h1>
          <p className={styles.Paragraph}>
            Sorry
            {' '}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>
            {' '}
            we couldn’t find what you were looking for.
            <br />
            <Link to="/">Go home</Link>
            .
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
